// General
//
// --------------------------------------

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #ffffff;
  transition: opacity .15s ease-in-out;

  &.fade-out {
    opacity: 0;
  }

  &::before {
    content: "";
    position: fixed;
    top: calc(50% - 0px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-top-color: var(--bs-dark);
    border-bottom-color: var(--bs-dark);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Navbar
//
// --------------------------------------

.navbar__logo {
  max-height: 40px;
}

// Full screen video
//
// --------------------------------------

video.fullscreen {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width:100%;
  height:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::-webkit-media-controls {
     display:none !important;
  }
}

// Hero
//
// --------------------------------------

.section_hero {
  position: relative;

  @include media-breakpoint-up(lg) {
    &::before,
    .hero__video:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% + 245px);
      height: 176px;
      z-index: 3;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }
}

// Video

.hero__video {
  z-index: -1;

  .gradient {
    position: absolute;
    right: 0;
    bottom: 190px;
    width: 100%;
    height: 400px;
    z-index: 3;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }

  @include media-breakpoint-up(lg) {
    position: fixed;

    &__scene {
      height: 832px;
    }

    &__phone {
      position: absolute;
      z-index: 2;
      top: 50px;
      left: -245px;
      height: 748px;
    }
  }

  @include media-breakpoint-down(lg) {
    position: relative;
    width: 100%;
    left: 0;
    overflow: hidden;
    margin-bottom: -450px;

    &__scene {
      height: 100vh;
      max-width: 275px;
      margin-top: -30px;
    }
  
    &__phone {
      position: absolute;
      z-index: 2;
      top: 90px;
      left:  calc(50% - 395px);
      width: 600px;
    }
  }
}

// Texts

.hero__text {
  &--1 {
    font-size: 155px;
    line-height: 128px;
    height: 280px;

    @include media-breakpoint-down(lg) {
      font-size: 155px * 0.5;
      line-height: 128px * 0.5;
      height: 280px * 0.5;
      margin-bottom: -50px;
    }
  }

  &--2 {
    position: relative;

    svg {
      overflow: visible;
    }

    @include media-breakpoint-up(lg) {
      z-index: -2;
      height: 250px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: -10px;
    }
  }

  &--3 {
    left: -50px;
    text-align: right;

    @include media-breakpoint-down(lg) {
      left: 0;
      height: auto;
      z-index: 4 !important;
    }
  }
}

.section_hero .hero__text--3 {
  @include media-breakpoint-up(lg) {
    height: 560px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: -300px;
  }
}

// Lotties

.lottie {
  &--social_media {
    @include media-breakpoint-up(lg) {
      margin-top: -30px;
    }
    
    position: absolute;
    width: 80%;
    height: 382px;
    z-index: 3;
    
    &::before {
      content: '';
      position: absolute;
      right: 50%;
      margin-right: -100px;
      width: 200px;
      height: 100px;
      z-index: 3;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }

  &--hand {
    @include media-breakpoint-up(lg) {
      width: 315px;
      height: 315px;
      margin-top: -74px;
    }

    position: absolute;
    z-index: 2;
    width: 200px;
    height: 200px;
    right: 0;
  }

  &--send {
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    margin-top: -140px;
    margin-left: -190px;

    // @include media-breakpoint-down(md) {
    // }
  }

  &--chat {
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    margin-top: -140px;
    margin-left: -190px;

    @include media-breakpoint-down(xl) {
      width: 230px;
      height: 230px;
      margin-top: -95px;
      margin-left: -160px;
    }
  }

  &--like {
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    margin-top: -155px;
    margin-left: -125px;

    @include media-breakpoint-down(xl) {
      width: 220px;
      height: 220px;
      margin-top: -130px;
    }
  }
}

// Custom utilities
//
// --------------------------------------

// Font size

.fs- {
  &45 {
    font-size: 45px;
    line-height: 45px;
    @include media-breakpoint-down(lg) {
      font-size: 45px * 0.7;
      line-height: 45px * 0.7;
    }
  }
  &60 {
    font-size: 60px;
    line-height: 60px;
    @include media-breakpoint-down(lg) {
      font-size: 60px * 0.7;
      line-height: 60px * 0.7;
    }
  }
  &82 {
    font-size: 82px;
    line-height: 82px;
    @include media-breakpoint-down(lg) {
      font-size: 82px * 0.7;
      line-height: 82px * 0.7;
    }
  }
  &90 {
    font-size: 90px;
    line-height: 90px;
    @include media-breakpoint-down(lg) {
      font-size: 90px * 0.7;
      line-height: 90px * 0.7;
    }
  }
}

// Shading text

.shading-text {
  text-shadow: -1.5px 0 black, 0 1.5px black, 1.5px 0 black, 0 -1.5px black;
  position: relative;
  color: white;
  font-weight: bold;
  font-size: 250px;
  line-height: 195px;
  z-index: 3;

  &-sm {
    font-size: 250px * 0.7;
    line-height: 195px * 0.7;

    span:before{
      margin: (20px * 0.7) 0 0 (20px * 0.7);
    }
  }

  @include media-breakpoint-down(lg) {
    font-size: 250px * 0.45;
    line-height: 195px * 0.45;

    &-sm {
      font-size: 250px * 0.35;
      line-height: 195px * 0.35;
  
      span:before{
        margin: (20px * 0.35) 0 0 (20px * 0.35);
      }
    }
  }
  
  span:before {
    content: attr(letters);
    position: absolute;
    text-shadow: none;
    color: #000434;
    z-index: -1;
    margin: 20px 0 0 20px;

    @include media-breakpoint-down(lg) {
      margin: 10px 0 0 10px;
    }
  }

  &-inverted {
    color: #000434;

    span:before {
      color: white;
      text-shadow: -1.5px 0 black, 0 1.5px black, 1.5px 0 black, 0 -1.5px black;
    }
  }

  &-big {
    @include media-breakpoint-up(lg) {
      font-size: 350px;
      line-height: 255px;
    }
  }
}

// Motion path

.motion-path {
  .follow-path {
    width: 144px;
    
    @include media-breakpoint-up(lg){
      top: -88px;
      left: -80px;
      font-size: calc(1.3rem + .6vw);

      .motion-path-circle {
        left: 57px;
        bottom: -44px;
      }
    }

    @include media-breakpoint-down(lg){
      top: -75px * .8;
      left: -80px * .8;
      font-size: calc(1.275rem + .3vw);
      width: 144px * .8;

      .motion-path-circle {
        left: 57px * .8;
        bottom: -34px * .8;
      }
    }

    .motion-path-circle {
      border: 2px solid white;
      background-color: #38d26d;
    }
  }
}

// Image hoberable

.img_hoverable {
  img {
    transition: opacity .15s ease-in-out, border .15s ease-in-out;
    border: 2px solid;
    border-color: white;
    border-radius: 36px;
  }

  img.position-absolute {
    opacity: 0;
    z-index: 2;
  }

  &:hover {
    img.position-absolute {
      opacity: 1;
      border-color: #000434;
    }
  }
}

// Special link

.special-link {
  background-color: #000434;
  color: white;
  font-size: 1.5rem;
  padding: .8rem 2.5rem;
  text-decoration: none;
  position: relative;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 2px #000434;
    background-color: #02B1FF;
    z-index: -1;
    left: 8px;
    top: 8px;
  }

  &:hover {
    color: #d1eaf5;
  }

  &,
  &::after {
    transition: color .15s ease-in-out;
    border-radius: .8rem;
  }
}

// Oulined text

.text-outline {
  color: rgba(13, 11, 11, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}
a.text-outline:hover {
  color: rgba(255, 255, 255, .0);
}

// Z ndex

.z-index- {
  &2 {
    z-index: 2;
  }
}

// Text gradients

.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;

  &-orange {
    background: -webkit-linear-gradient(#e83e33, #f7db83);

    &-horizontal {
      background: -webkit-linear-gradient(left, #e83e33, #f7db83);
    }
  }

  &-purple {
    background: -webkit-linear-gradient(#fd0a7b, #1455fa);

    &-horizontal {
      background: -webkit-linear-gradient(left, #fd0a7b, #1455fa);
    }
  }

  &-pink {
    background: -webkit-linear-gradient(#fb1c6f, #65a7da);

    &-horizontal {
      background: -webkit-linear-gradient(left, #fb1c6f, #65a7da);
    }
  }
}

// Background gradients

.big-gradient {
  background: rgb(254,117,81);
  background: -moz-linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
  background: -webkit-linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
  background: linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7551",endColorstr="#1da4f5",GradientType=1);
}

.bg-gradient {
  &-purple {
    background: rgb(253,50,119);
    background: -moz-linear-gradient(145deg, rgba(253,50,119,1) 0%, rgba(107,87,222,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(253,50,119,1) 0%, rgba(107,87,222,1) 100%);
    background: linear-gradient(145deg, rgba(253,50,119,1) 0%, rgba(107,87,222,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fd3277",endColorstr="#6b57de",GradientType=1);
  }

  &-orange {
    background: rgb(255,137,19);
    background: -moz-linear-gradient(145deg, rgba(255,137,19,1) 0%, rgba(245,200,157,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(255,137,19,1) 0%, rgba(245,200,157,1) 100%);
    background: linear-gradient(145deg, rgba(255,137,19,1) 0%, rgba(245,200,157,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8913",endColorstr="#f5c89d",GradientType=1);
  }

  &-blue {
    background: rgb(143,111,208);
    background: -moz-linear-gradient(145deg, rgba(143,111,208,1) 0%, rgba(1,164,255,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(143,111,208,1) 0%, rgba(1,164,255,1) 100%);
    background: linear-gradient(145deg, rgba(143,111,208,1) 0%, rgba(1,164,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8f6fd0",endColorstr="#01a4ff",GradientType=1);
  }

  &-green {
    background: rgb(116,226,96);
    background: -moz-linear-gradient(145deg, rgba(116,226,96,1) 0%, rgba(48,219,213,1) 100%);
    background: -webkit-linear-gradient(145deg, rgba(116,226,96,1) 0%, rgba(48,219,213,1) 100%);
    background: linear-gradient(145deg, rgba(116,226,96,1) 0%, rgba(48,219,213,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#74e260",endColorstr="#30dbd5",GradientType=1);
  }
}

.special-shadow {
  &,
  * {
    position: relative;
    z-index: 1;
  }

  &.big-gradient {
    &-1::after {
      content: '';
      z-index: 0;
      background: rgb(254,117,81);
      background: -moz-linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
      background: -webkit-linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
      background: linear-gradient(145deg, rgba(254,117,81,1) 0%, rgba(254,208,153,1) 33%, rgba(111,80,216,1) 66%, rgba(29,164,245,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7551",endColorstr="#1da4f5",GradientType=1);
    }

    &-2::after {
      content: '';
      z-index: 0;
      background: rgb(112,58,209);
      background: -moz-linear-gradient(0deg, rgba(112,58,209,1) 0%, rgba(254,49,115,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(112,58,209,1) 0%, rgba(254,49,115,1) 100%);
      background: linear-gradient(0deg, rgba(112,58,209,1) 0%, rgba(254,49,115,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#703ad1",endColorstr="#fe3173",GradientType=1);
    }

    &-3::after {
      content: '';
      z-index: 0;
      background: rgb(255,62,0);
      background: -moz-linear-gradient(90deg, rgba(255,62,0,1) 0%, rgba(255,205,0,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(255,62,0,1) 0%, rgba(255,205,0,1) 100%);
      background: linear-gradient(90deg, rgba(255,62,0,1) 0%, rgba(255,205,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3e00",endColorstr="#ffcd00",GradientType=1);
    }

    &-4::after {
      content: '';
      z-index: 0;
      background: rgb(82,42,213);
      background: -moz-linear-gradient(90deg, rgba(82,42,213,1) 0%, rgba(0,166,252,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(82,42,213,1) 0%, rgba(0,166,252,1) 100%);
      background: linear-gradient(90deg, rgba(82,42,213,1) 0%, rgba(0,166,252,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#522ad5",endColorstr="#00a6fc",GradientType=1);
    }

  }

  &::before {
    content: '';
    z-index: -1;
    margin: 20px 0 0 20px;
    border: solid 2px #000434;
    background-color: #FFD6FA;
  }

  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 2rem;
  }

}

// Global background and text color

.bg-g-dark {
  background-color: #000434;
}

.text-red {
  color: #FF0037;
}