// Source Sans Pro
//
// --------------------------------------

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Regular.woff2') format('woff2'),
      url('../fonts/source-sans-pro/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Bold.woff2') format('woff2'),
      url('../fonts/source-sans-pro/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.text-ssp {
  font-family: "Source Sans Pro";
}

// Nimbus Sans
//
// --------------------------------------

@import url("https://use.typekit.net/izl8qks.css");